export const ASK_BOT_ENDPOINT = "/bot/ask";
export const FEEDBACK_ENDPOINT = '/feedback';
export const LOAD_HISTORY_ENDPOINT = '/bot/history';

// APP LANGUAGES
export const EN = "en";
export const MK = "mk";
export const APP_LANGUAGES = [EN, MK];

//USER ROLES
export const ADMIN_ROLE = "Admin";
export const USER_ROLE = "User";
export const VALIDATOR_ROLE = "Validator";
export const USER_ROLES = "isRoles";

//USER ACTIVE
export const USER_ACTIVITIES = "isActivities";
export const ITEM_ACTIVE = "Activate";
export const ITEM_DISABLE = "Deactivate";

//LOCALSE STORAGE CONSTS
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const USER = "user";
export const USER_LOGGED = "userLogged";
export const APP_LANG = "appLang";
export const MEMORY_KEY = "MemoryKey"

//PAGINATION PAGE SIZE
export const USERS_ADMIN_PAGE_SIZE = 7;
export const CATEGORIES_ADMIN_PAGE_SIZE = 7;
export const CATEGORY_ELT_ADMIN_PAGE_SIZE = 7;

// ADMINS URL
export const USERS_URL = "USERS_URL";
export const CATEGORIES_URL = "CATEGORIES_URL";
export const CATEGORY_ELT_URL = "CATEGORY_ELT_URL";

//URL CONST
export const VIDEO_PREVIEW_VALIDATION_URL = "/video_preview_validation";

//COLORS

export const APP_COLOR = {
  PRIMARY_1: "#26A1A9",
  PRIMARY_2: "#7CDBE1",
  PRIMARY_3: "#23858B",
  SECONDARY_1: "#90BC3A",
  SECONDARY_2: "#B9E85D",
  SECONDARY_3: "#97b35f",
  ACCENT_1: "#343532",
  ACCENT_2: "#4F514A",
  WHITE_1: "#FFFFFF",
  WHITE_2: "#F4F4F4",
  DANGER: "#D23737",
  GRAY_1: "#E7E7E7",
  GRAY_2: "#9A9C96",
  GRAY_TEAL: "#95bfbf",
  GOLD: "#FFB61D",
  BLUE: "#5592D9",
};

// RADIO MODAL DATA
export interface IRadioBarData {
  id: number;
  value: string;
  option: string;
}

export const USER_ROLE_RADIO_DATA = [
  {
    id: 1,
    value: ADMIN_ROLE,
    option: ADMIN_ROLE,
  },
  {
    id: 2,
    value: VALIDATOR_ROLE,
    option: VALIDATOR_ROLE,
  },
  {
    id: 3,
    value: USER_ROLE,
    option: USER_ROLE,
  },
];

export const ITEM_ACTIVE_RADIO_DATA = [
  {
    id: 1,
    value: ITEM_ACTIVE,
    option: ITEM_ACTIVE,
  },
  {
    id: 2,
    value: ITEM_DISABLE,
    option: ITEM_DISABLE,
  },
];