import styles from "./Home.module.css";
import { Typography, Space, Input, Button, Alert } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MEMORY_KEY, ASK_BOT_ENDPOINT, FEEDBACK_ENDPOINT, LOAD_HISTORY_ENDPOINT } from "../../utils/appConsts";
import logoImage from '../../assets/logo.png';
import CustomModal from "../../components/FeedbackPrompt/feedbackPrompt";
import delfinaAvatar from '../../assets/delfina.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { TextArea } = Input;
const { Title } = Typography;
const CHARACTER_LIMIT = 800;

function Home() {
    const [loadingAsk, setLoadingAsk] = useState<boolean>(false);
    const [askButtonDisabled, setAskButtonDisabled] = useState<boolean>(true);
    const [post, setPost] = useState({ question: "" });
    const [response, setResponse] = useState("");
    const [error, setError] = useState<Error>();
    const [history, setHistory] = useState([]);
    const [userQuestion, setUserQuestion] = useState('');
    const [generatedQuestion, setGeneratedQuestion] = useState('');
    const [userInput, setUserInput] = useState('');
    const [seeFeedbackButtons, setSeeFeedbackButtons] = useState(true)
    const [isTyping, setIsTyping] = useState(false)
    const [isWarned, setIsWarned] = useState(false)
    const [isNotified, setisNotified] = useState(false)

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUserInput(e.target.value);
        setPost({ ...post, [e.target.name]: e.target.value });

        if (!isWarned && e.target.value && e.target.value.length >= CHARACTER_LIMIT) {
            toast.warn(`You have reached the maximum character limit of ${CHARACTER_LIMIT}.`, {
                onClose: () => { 
                    setIsWarned(false);
                }
            });
            setIsWarned(true);
        }
        if (e.target.value.trim() !== '') {
            setAskButtonDisabled(false)
        }
        else {
            setAskButtonDisabled(true)
        }

    };

    const notify = () => toast.success("Thank you for your feedback!", {
        onClose: () => { 
            setisNotified(false);
        }
    });

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleModalSubmit = (inputValue: string) => {
        handleFeedback(false, inputValue);
    };

    const handleFeedback = async (isGood: boolean, correctAnswer: string = '') => {
        const feedback = {
            question: generatedQuestion,
            answer: response,
            is_good: isGood,
            correct_answer: correctAnswer,
        };
        try {
            await axios.post(`${process.env.REACT_APP_CHATBOT_URL}` + FEEDBACK_ENDPOINT, feedback, {
                headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            });
            notify();
            setisNotified(true);
            
        } catch (error) {
            setError(error as Error);
        }
        setSeeFeedbackButtons(false)
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                let key = localStorage.getItem(MEMORY_KEY)
                if (!key || key === '') {
                    return
                }
                const response = await axios.get(
                    process.env.REACT_APP_CHATBOT_URL + LOAD_HISTORY_ENDPOINT,
                    {
                        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                        params: { key: key }
                    }
                );
                if (response.data.length > 0) {
                    setHistory(response.data)
                    setUserQuestion(response.data[response.data.length - 1][0])
                    setResponse(response.data[response.data.length - 1][1])
                }
            } catch (error) {
                setError(error as Error)
            }
        };
        fetchData();
    }, [])

    const handleClick = async () => {
        const inputElement = document.getElementById('userInput') as HTMLInputElement;
        setLoadingAsk(!loadingAsk);
        setUserInput('');
        inputElement.disabled = true;
        setIsTyping(true);
        var key = localStorage.getItem(MEMORY_KEY)
        try {
            const response = await axios.post(
                process.env.REACT_APP_CHATBOT_URL + ASK_BOT_ENDPOINT,
                {},
                {
                    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                    params: { question: post.question, key: key }
                }
            );

            setResponse(response.data.answer);
            const userQuestion = post.question;
            setUserQuestion(userQuestion);
            localStorage.setItem(MEMORY_KEY, response.data.key);
            setHistory(response.data.history);
            setGeneratedQuestion(response.data.generated_question);
            let div = document.getElementById('chatHistory');
            if (div) {
                div.scrollTop = div.scrollHeight;
            }
        } catch (error) {
            setError(error as Error)
        }
        setLoadingAsk(loadingAsk => !loadingAsk);
        setIsTyping(false);
        setAskButtonDisabled(true);
        inputElement.disabled = false;
        setSeeFeedbackButtons(true)
    };


    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (userInput.trim() === '') {
            return
        }
        if (event.key === 'Enter') {
            handleClick();
        }
    };

    const handleEraseError = () => {
        setError(undefined);
    };

    const handleButtonClick = (isGood: boolean) => {
        if (isGood) {
            handleFeedback(true);
        } else {
            setIsModalOpen(true);
        }
    };

    return (
        <div className={styles.contentContainer}>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
                placeholder="Type the correct answer"
            />
            <Title className={styles.title}>
                <img width={200} src={logoImage} alt="Logo" />
            </Title>
            <div className={styles.chatHeaderContainer}>
                    <img src={delfinaAvatar} width={55} height={55} alt="Delfina Avatar" />
                    <h3>Delfina</h3>
                </div>
            <div className={styles.chatContainer}>
                    <div className={styles.chatHistory} id="chatHistory">
                        {history && history.length > 0 &&
                            history.slice(0, -1).map((list, i) => (
                                <div key={i} className={styles.chatBubble}>
                                    <div className={styles.userBubble}>
                                        <div className={styles.userQuestion}>{`${list[0]}`}</div>
                                    </div>
                                    <div className={styles.botBubble}>
                                        <img src={delfinaAvatar} alt="Delfina Avatar" className={styles.botAvatar} />
                                        <div style={{ backgroundColor: '#99ba5854' }} className={styles.botResponse}
                                            dangerouslySetInnerHTML={{ __html: list[1] }}>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {userQuestion && (
                            <div key={userQuestion} className={styles.chatBubble}>
                                <div className={styles.userBubble}>
                                    <div className={styles.userQuestion}>{`${userQuestion}`}</div>
                                </div>
                                <div className={styles.botBubble}>
                                    <img src={delfinaAvatar} alt="Delfina Avatar" className={styles.botAvatar} />

                                    <div className={styles.responseArea}>
                                        <div className={styles.response}>
                                            {response ? (
                                                <div dangerouslySetInnerHTML={{ __html: response }} />
                                            ) : (
                                                'The response will be shown here.'
                                            )}

                                        </div>
                                        <Space.Compact style={{
                                            width: '90%',
                                            justifyContent: 'start',
                                            marginTop: '10px',
                                            paddingLeft: '10px'
                                        }}>
                                            {seeFeedbackButtons && (
                                                <>
                                                    <LikeOutlined className={styles.likeIcon}
                                                        onClick={() => handleButtonClick(true)}
                                                        onPointerEnterCapture={() => { }}
                                                        onPointerLeaveCapture={() => { }} />
                                                    <DislikeOutlined className={styles.dislikeIcon}
                                                        onClick={() => handleButtonClick(false)}
                                                        onPointerEnterCapture={() => { }}
                                                        onPointerLeaveCapture={() => { }} />
                                                </>
                                            )}
                                        </Space.Compact>

                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        )}
                        {isTyping && (
                            <div key='isTyping' className={styles.chatBubble}>
                                <div className={styles.userBubble}>
                                    <div className={styles.userQuestion}>{`${post.question}`}</div>
                                </div>
                                <div className={styles.botBubble}>
                                    <img src={delfinaAvatar} alt="Delfina Avatar" className={styles.botAvatar} />
                                    <div className={styles.responseArea}>
                                        <div className={`${styles.typingIndicator} ${styles.response}`}>
                                            <div className={styles.bouncingDots}>
                                                <span></span><span></span><span></span>
                                            </div>
                                        </div>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.inputArea}>
                        <Space.Compact style={{ width: '100%' }}>
                            <TextArea style={{ height: '60px', resize: 'none'}} maxLength={CHARACTER_LIMIT} rows={1} placeholder="Ask a question" name="question" value={userInput}
                                onChange={handleInput} onKeyDown={(event) => handleKeyDown(event)} id='userInput'>
                            </TextArea>
                            <ToastContainer />
                            <Button style={{ height: '60px' }} type="primary" loading={loadingAsk} disabled={askButtonDisabled}
                                onClick={handleClick}>Ask</Button>
                        </Space.Compact>
                    </div>
            </div>

            {error &&
                <Alert className={styles.alerts}
                    message="Error"
                    description={error.message}
                    type="error"
                    showIcon
                    action={
                        <Space>
                            <Button type="text" size="small" ghost onClick={handleEraseError}>
                                Done
                            </Button>
                        </Space>
                    } />
            }
        </div>
    );
}

export default Home;
