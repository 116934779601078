import { FC } from "react";
import { Navigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../../utils/appConsts";

interface IPublicRouteProps {
  component: FC;
}

export const PublicRoute = ({ component: Component }: IPublicRouteProps) => {
  return !localStorage.getItem(ACCESS_TOKEN) ? (
    <Component />
  ) : (
    <Navigate to="/" />
  );
};
