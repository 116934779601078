import { FC } from "react";
import { Navigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../../utils/appConsts";

interface IPrivateRouteProps {
  component: FC;
}

export const PrivateRoute = ({ component: Component }: IPrivateRouteProps) => {
  return localStorage.getItem(ACCESS_TOKEN) ? (
    <Component />
  ) : (
    <Navigate to="/login" />
  );
};
