import React, {FC, useState} from 'react';
import Modal from 'react-modal';
import {Typography, Space, Input, Button, Alert, Upload} from 'antd';

interface CustomModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSubmit: (inputValue: string) => void;
    placeholder?: string;
}

const CustomModal: FC<CustomModalProps> = ({isOpen, onRequestClose, onSubmit, placeholder}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true)

    const handleSubmit = () => {
        onSubmit(inputValue);
        setInputValue('');
        onRequestClose();
    };

    const handleClose = () => {
      setInputValue('');
      onRequestClose();
    };
    const handleCloseX = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault()
      handleClose()
    }

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.trim() === ''){
        setDisabledSubmit(true)
      }else{
        setDisabledSubmit(false)
      }
      setInputValue(e.target.value)
    }

      const customStyles = {
    content: {
      width: '50%', // Adjust the width as needed
      margin: 'auto', // Center the modal horizontally
        border:'1px solid black',
        borderRadius:'15px',
        background: 'white',
        marginTop: '150px',
        padding:'5px 15px',
        marginBottom: '20px'
    },
  };

    return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="correctAnswerFeedbackModal" style={customStyles}>
      <div style={{display:'flex',}}>
      <h2 style={{width:'95%', marginTop:'10px'}}>Please Enter Correct Answer</h2>
      <a href='#' onClick={handleCloseX} style={{textDecoration:'none', color:'black'}}><h2 style={{marginTop:'10px'}}>X</h2></a>
      </div>

      <p>This will help us improve the quality of the responses.</p>
      <textarea
        value={inputValue}
        onChange={handleInput}
        placeholder={placeholder}
        rows={5}
        style={{
          width: '98%', // Full width input
          padding: '8px',
            resize: 'vertical',
          border: '1px solid #ccc',
          borderRadius: '4px',
          marginBottom: '10px',
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom:'10px', marginTop:'5px' }}>
        <Button onClick={handleSubmit} disabled={disabledSubmit} type="primary" style={{ padding: '4px 12px 6px 12px', width: '80px' }}>
          Submit
        </Button>
        <Button onClick={handleClose} type="default" style={{ marginLeft: '20px', padding: '4px 12px 6px 12px', width: '80px' }}>
          Cancel
        </Button>
      </div>
    </Modal>

    );
};

export default CustomModal;