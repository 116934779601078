import React from 'react';
import { ConfigProvider } from 'antd';
import { Routes, Route, Navigate } from "react-router-dom";
import { APP_COLOR } from "./utils/appConsts";
import { PublicRoute } from "./components/NavigationRoutes";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import { Footer } from "antd/es/layout/layout";
import styles from "./App.module.css";


function App() {
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: APP_COLOR.PRIMARY_1,
            colorPrimaryHover: APP_COLOR.PRIMARY_3,
          },
        }}
      >
        <Routes>
          {/* <Route path="/login" element={<PublicRoute component={SSOLogin} />} /> */}
          
         
          <Route path="/" element={<PublicRoute component={Home} />} />
         
          <Route path="/error" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
        {/* <Footer className={styles.footer}> ©2024 IT Labs </Footer> */}
      </ConfigProvider>
    </div>
  );
}

export default App;