import { Button } from "antd";
import styles from "./NotFound.module.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>404 | Page Not Found</div>
      <Link className={styles.button} to="/">
              <Button type="primary" >Go to home</Button>
      </Link>
    </div>
  );
};

export default NotFound;
